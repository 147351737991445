<template>
  <div>
    <v-card class="rounded-md mb-4">
      <div class="d-flex align-center justify-start px-4 py-3 flex-wrap">
        <v-btn
          depressed
          @click="refreshItems()"
          :loading="loading.refresh"
          class="mr-2"
        >
          <v-icon left>mdi-refresh</v-icon>
          {{ $t("btn.refresh") }}
        </v-btn>
        <v-btn depressed @click="getFilter()" :loading="loading.filter">
          <v-icon left>mdi-filter-variant</v-icon>
          {{ $t("btn.filter") }}
        </v-btn>
        <div class="ml-auto" :class="{ 'mb-2': $vuetify.breakpoint.smAndDown }">
          <v-btn
            outlined
            v-if="meta.total"
            color="primary"
            :loading="loading.filter || loading.refresh || loading.list"
            ><span class="font-weight-bold">{{
              $t("elements", { items: $utils.pad(meta.total, 2) }).toUpperCase()
            }}</span></v-btn
          >
        </div>
      </div>
    </v-card>
    <v-card>
      <v-card-title>
        <div class="font-weight-bold text-subtitle-1">
          {{ $t("bulksms.sms.sent.title") }}
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        :loading="loading.list"
        :headers="headers"
        :items="items"
        :options.sync="pagination"
        :footer-props="footerProps"
        :items-per-page="itemPerPage"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        :server-items-length="meta.total"
      >
        <template v-slot:body="{ items }" v-if="!_.isEmpty(items)">
          <tbody>
            <template v-for="item in items">
              <v-hover :key="item.id" v-slot:default="{ hover }">
                <tr>
                  <td style="min-width: 150px">
                    {{ $moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}
                  </td>
                  <td style="min-width: 80px">{{ item.da }}</td>
                  <td style="min-width: 110px">{{ item.sender }}</td>
                  <td>
                    <v-chip class="table-chips text-body-2" color="fourth">
                      <span>{{ $utils.pad(item.cost, 2) }}</span>
                    </v-chip>
                  </td>
                  <td>
                    <template v-if="item.trackingStatus">
                      <v-chip
                        v-if="item.trackingStatus === 'DELIVERED'"
                        class="table-chips text-body-2"
                        color="success"
                      >
                        <span class="white--text">{{
                          $t("bulksms.campaign.fields.dlr.DELIVERED")
                        }}</span>
                      </v-chip>
                      <v-chip
                        v-else-if="
                          item.trackingStatus === 'MESSAGE_INTERMEDIATE_STATE'
                        "
                        class="table-chips text-body-2"
                        color="amber darken-4"
                      >
                        <span class="white--text">{{
                          $t(
                            "bulksms.campaign.fields.dlr.MESSAGE_INTERMEDIATE_STATE"
                          )
                        }}</span>
                      </v-chip>
                      <v-chip
                        v-else
                        class="table-chips text-body-2"
                        color="error"
                      >
                        <span class="white--text">{{
                          $t(
                            "bulksms.campaign.fields.dlr." + item.trackingStatus
                          )
                        }}</span>
                      </v-chip>
                    </template>
                    <template v-else>
                      <v-chip class="table-chips text-body-2" color="warning">
                        <span class="white--text">{{
                          $t("bulksms.campaign.fields.dlr.PENDING")
                        }}</span>
                      </v-chip>
                    </template>
                  </td>
                  <td>
                    <v-chip
                      v-if="$utils.isSmsSource(item.source)"
                      class="table-chips text-body-2"
                      color="fourth"
                    >
                      <span>{{
                        $t("bulksms.campaign.fields.source." + item.source)
                      }}</span>
                    </v-chip>
                    <v-chip
                      v-else
                      class="table-chips text-body-2"
                      color="grey lighten-2"
                    >
                      <span>{{ item.source }}</span>
                    </v-chip>
                  </td>
                  <td :title="item.content" style="min-width: 250px">
                    {{ $utils.truncate(item.content, [160, 50]) }}
                  </td>
                  <td style="min-width: 80px">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="getSms(item.id)"
                          x-small
                          icon
                          v-bind="attrs"
                          v-on="on"
                          v-show="hover || $vuetify.breakpoint.mdAndDown"
                        >
                          <v-icon color="primary">mdi-eye-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("btn.display.description") }}</span>
                    </v-tooltip>
                  </td>
                </tr>
              </v-hover>
            </template>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
    <sms-sent-view :sms="sms" :dialog="dialog.view"></sms-sent-view>
    <sms-sent-filter
      @filter="applyFilter"
      :dialog="dialog.filter"
    ></sms-sent-filter>
  </div>
</template>

<script>
import SmsSentView from "./sent-view.vue";
import SmsSentFilter from "./sent-filter.vue";
import ListMixin from "./../../../mixins/commons/list";
export default {
  mixins: [ListMixin],
  metaInfo() {
    return {
      title: this.$t("bulksms.sms.sent.title"),
    };
  },
  data: () => ({
    path: "/api/providers/bulksms/messages",
    dialog: {
      view: {
        display: false,
        loading: false,
      },
      filter: {
        display: false,
      },
    },
    sms: {},
  }),
  methods: {
    setHeaders() {
      this.headers = [
        {
          text: this.$t("bulksms.campaign.fields.created.title"),
          value: "createAt",
          align: "start",
          sortable: true,
          width: 130,
        },
        {
          text: this.$t("bulksms.campaign.fields.numbers.title2"),
          value: "da",
          align: "start",
          sortable: false,
          width: 80,
        },
        {
          text: this.$t("bulksms.campaign.fields.sender.title2"),
          value: "sender",
          align: "start",
          sortable: false,
          width: 100,
        },
        {
          text: this.$t("bulksms.campaign.fields.cost.title"),
          value: "cost",
          align: "start",
          sortable: false,
          width: 70,
        },
        {
          text: this.$t("bulksms.campaign.fields.dlr.title"),
          value: "dlr",
          align: "start",
          sortable: false,
          width: 80,
        },
        {
          text: this.$t("bulksms.campaign.fields.source.title"),
          value: "source",
          align: "start",
          sortable: false,
          width: 80,
        },
        {
          text: this.$t("bulksms.campaign.fields.content.title2"),
          value: "content",
          align: "start",
          sortable: false,
        },
        {
          text: "",
          align: "start",
          width: 100,
          sortable: false,
        },
      ];
    },
    async getSms(id) {
      this.dialog.view.loading = true;
      this.dialog.view.display = true;

      try {
        const response = await this.request({
          url: `/api/providers/bulksms/messages/${id}`,
        });
        this.sms = response.data;
      } catch (error) {
        let message = "error_codes.error_occured";
        this.dialog.view.display = false;
        if (error && error.response) {
          const status = error.presponse.status;
          if (status === 422 && error.response.data.details.code === 404) {
            message = "bulksms.errors.smsbad_request";
          }
        }
        this.notify({ message: this.$t(message) });
      }
      this.dialog.view.loading = false;
    },
    getFilter() {
      this.dialog.filter.display = true;
    },
  },
  components: {
    SmsSentView,
    SmsSentFilter,
  },
};
</script>