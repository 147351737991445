<template>
  <div>
    <sms-sent></sms-sent>
  </div>
</template>

<script>
import SmsSent from "./../../../components/bulksms/sms/sent";
export default {
  data: () => ({
  }),
  components: {
    SmsSent,
  },
};
</script>