<template>
  <v-dialog v-model="dialog.display" scrollable max-width="500px">
    <v-card>
      <v-card-title>
        <div class="text-subtitle-1">
          <span class="secondary-font font-weight-bold">{{
            $t("bulksms.sms.display.title")
          }}</span>
        </div>
        <v-btn class="ml-auto" icon @click="dialog.display = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text v-if="dialog.loading === false" class="px-3">
        <v-container fluid grid-list-md v-if="!_.isEmpty(sms)">
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12" sm="8">
              <div>
                <v-icon class="mr-2 pb-1">mdi-calendar-blank-outline</v-icon>
                <span class="text-body-1 font-weight-bold">{{
                  $t("bulksms.campaign.fields.created.title")
                }}</span>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="text-right">
                <v-chip color="fourth" class="table-chips mr-0">
                  <span class="font-weight-bold">{{
                    $moment(sms.createdAt).format("YYYY-MM-DD HH:mm:ss")
                  }}</span>
                </v-chip>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12" sm="8">
              <div>
                <v-icon class="mr-2 pb-1">mdi-account-arrow-right-outline</v-icon>
                <span class="text-body-1 font-weight-bold">{{
                  $t("bulksms.campaign.fields.sender.title2")
                }}</span>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="text-right">
                <v-chip color="fourth" class="table-chips mr-0">
                  <span class="font-weight-bold">{{ sms.sender }}</span>
                </v-chip>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12" sm="8">
              <div>
                <v-icon class="mr-2 pb-1">mdi-contacts-outline</v-icon>
                <span class="text-body-1 font-weight-bold">{{
                  $t("bulksms.campaign.fields.numbers.title2")
                }}</span>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="text-right">
                <v-chip color="fourth" class="table-chips mr-0">
                  <span class="font-weight-bold">{{ sms.da }}</span>
                </v-chip>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12" sm="8">
              <div>
                <v-icon class="mr-2 pb-1">mdi-wifi-strength-outline</v-icon>
                <span class="text-body-1 font-weight-bold">{{
                  $t("bulksms.campaign.fields.operator.title")
                }}</span>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="text-right">
                <v-chip color="fourth" class="table-chips mr-0">
                  <span class="font-weight-bold">{{
                    $t("bulksms.campaign.fields.operator." + sms.operator)
                  }}</span>
                </v-chip>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12" sm="8">
              <div>
                <v-icon class="mr-2 pb-1">mdi-source-commit</v-icon>
                <span class="text-body-1 font-weight-bold">{{
                  $t("bulksms.campaign.fields.source.title")
                }}</span>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="text-right">
                <v-chip
                  v-if="$utils.isSmsSource(sms.source)"
                  class="table-chips mr-0"
                  color="fourth"
                >
                  <span class="font-weight-bold">{{
                    $t("bulksms.campaign.fields.source." + sms.source)
                  }}</span>
                </v-chip>
                <v-chip v-else class="table-chips fourth" color="fourthh">
                  <span class="font-weight-bold">{{ sms.source }}</span>
                </v-chip>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12" sm="8">
              <div>
                <v-icon class="mr-2 pb-1">mdi-counter</v-icon>
                <span class="font-weight-bold">{{
                  $t("bulksms.campaign.fields.cost.title2")
                }}</span>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="text-right">
                <v-chip color="fourth" class="table-chips mr-0">
                  <span class="font-weight-bold"
                    >{{ $utils.pad(sms.cost, 2) }} SMS</span
                  >
                </v-chip>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12" sm="8">
              <div>
                <v-icon class="mr-2 pb-1"
                  >mdi-message-arrow-left-outline</v-icon
                >
                <span class="text-body-1 font-weight-bold">{{
                  $t("bulksms.campaign.fields.dlr.title")
                }}</span>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="text-right">
                <template v-if="sms.trackingStatus">
                  <v-chip
                    v-if="sms.trackingStatus === 'DELIVERED'"
                    class="table-chips mr-0"
                    color="success"
                  >
                    <span class="white--text">{{
                      $t("bulksms.campaign.fields.dlr.DELIVERED")
                    }}</span>
                  </v-chip>
                  <v-chip
                    v-else-if="
                      sms.trackingStatus === 'MESSAGE_INTERMEDIATE_STATE'
                    "
                    class="table-chips text-body-2"
                    color="amber darken-4"
                  >
                    <span class="white--text">{{
                      $t(
                        "bulksms.campaign.fields.dlr.MESSAGE_INTERMEDIATE_STATE"
                      )
                    }}</span>
                  </v-chip>
                  <v-chip
                    v-else
                    class="table-chips text-body-2 mr-0"
                    color="error"
                  >
                    <span class="white--text">{{
                      $t("bulksms.campaign.fields.dlr." + sms.trackingStatus)
                    }}</span>
                  </v-chip>
                </template>
                <template v-else>
                  <v-chip class="table-chips text-body-2 mr-0" color="warning">
                    <span class="white--text">{{
                      $t("bulksms.campaign.fields.dlr.PENDING")
                    }}</span>
                  </v-chip>
                </template>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <template v-if="sms.trackingStatus && sms.trackedAt">
            <v-row align="center" justify="center" class="py-2">
              <v-col cols="12" sm="8">
                <div>
                  <v-icon class="mr-2 pb-1">mdi-calendar-clock</v-icon>
                  <span class="text-body-1 font-weight-bold">{{
                    $t("bulksms.campaign.fields.dlr_date.title")
                  }}</span>
                </div>
              </v-col>
              <v-col cols="12" sm="4">
                <div class="text-right">
                  <v-chip color="fourth" class="table-chips mr-0">
                    <span class="text-body-2">{{
                      $moment(sms.trackedAt).format("YYYY-MM-DD HH:mm:ss")
                    }}</span>
                  </v-chip>
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </template>
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12">
              <div
                class="text-body-1 font-weight-bold grey--text text--darken-2"
                style="word-break: break-all"
              >
                {{ sms.content }}
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-text v-else>
        <div class="text-center mt-5">
          <v-progress-circular
            indeterminate
            color="secondary"
            width="3"
            size="50"
          ></v-progress-circular>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Object,
      default: function () {
        return {
          display: false,
          loading: true,
        };
      },
    },
    sms: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data: () => ({}),
  methods: {},
};
</script>